import React from 'react'
import Layout  from '../components/common/Layout'
import { WorkAndClients } from '../components/common/promo/WorkAndClients';


const AboutPage = ({data}) => {


    // TODO - site name should not be hard coded
    const siteName = "White Owl Education"
    const title = "About the Instructor"
    const description = "Ralph Brooks has a MBA from UCLA Anderson, and he has delivered significant results companies such as Toyota, AT&T and Ernst & Young."
    const cardImage = "https://whiteowleducation.com/social.png"


    return (
    <Layout
        twitterInfo={{"title": `${title}`, 
                      "card": "summary_large_image",
                      "description": `${description}`,
                      "imgsrc": `${cardImage}`,
                      "site": "@whiteowled"
                    }}
        fbInfo={{
                "image": `${cardImage}`,
                "description": `${description}`,
                "title": `${title}`,
                "type": "website",
                "site_name": `${siteName}`
        }}
        pageInfo={{
                "title": `${title}`, 
                "description": `${description}`, 
        }}
    >
        <WorkAndClients
            svgList={['toyota', 'att', 'amdocs', 'consumers', 'raytheon', 'heb', 'ey', 'sprint']}
        />


    </Layout>
)
}

export default AboutPage
