import styled from "styled-components";
import React from 'react'
import { css } from '@emotion/css'

import tw, { theme, GlobalStyles } from "twin.macro"
// import '../../../styles/about.css'

import '../../../styles/typography.css'

// This is the main part 

function getSVGS(filenames?: string[]): [any] {
    let elements:any = [];
    if (filenames != null && filenames.length > 0 ) {

        elements = filenames.map((filename) => {
            const classname = `img-${filename}`
            // const srcFilename = `/images/about/clients/${filename}.svg`
            const srcFilename = `/images/about/clients/${filename}.svg`
            const altText = `Logo of ${filename}`

            return (
                <div className="views-row" key={`views-row-${classname}`}>
                    <div className="views-field" key={`views-field-${classname}`}>
                        <div>
                            <div className="field-content" key={`field-content-${classname}`}>
                                {/* You are going to want to convert to SVG flag */}
                                {/* TODO - Changing width from 256 to 117 */}
                                {/* There is no link between size of svg and the css min-icon-width */}
                                <img className={classname} key={classname} src={srcFilename} width="256" height="82" alt={altText} />
                            </div>
                        </div>
                    </div>
                </div>
            );
            
        });
        
    } 

    return elements

}

const maxBannerWidth = "1120px";
const minIconWidth = "256px";

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;

/* There is a problem loading up the font */
/* @font-face{
    font-family:"Helvetica Neue LT W05 35 Thin";
    src:url("../fonts/helvetica-neue-35-thin.woff2") format("woff2");
    font-display: swap;
} */

.work {
    max-width: 1120px;
    margin: auto;
}

.bg-gloss-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 164, 161, 0.411);
    /* background-color: rgba(27,31,36,var(--tw-bg-opacity)); */
}

/* konrad.com/about */
.title {
    position: relative;
    z-index: 99;
    color: black;
    padding-top: 100px;
    margin-bottom: 80px;
    font-family: "Helvetica Neue LT W05 35 Thin";
    font-weight: 20;
    /* font-size: 2.75rem; */
    font-size: 50px;
    line-height: 1.29;
}

.kg-standard__subheading {
    font-size: 18px;
    color: #15191b;
    font-weight: bold;
    margin-bottom: 10px;
}

.kg-standard__copy {
    font-size: 18px;
    line-height: 1.78;
    color: #546672;
    
}
`


// {getSVGS(svgList)}

const UnstyledWorkAndClients: React.FC<{svgList: string[], className?: string}> = ({ svgList, className, children }) => {
    // inspired by https://www.konrad.com/about
    return(
        <>
            <div className={tailwindStyle}>
                <GlobalStyles />
                <section className="bg-gloss-gray-900" aria-label="Founder works with Top Fortune 500 Companies">
                    <div className="work kg-cap__about__clients"> 
                        {/* Place a container to get some minimum margin    */}
                        <div className="kg-cap__about__clients__container">
                            <h2 className="title">Previous Work and Clients</h2>
                            <div tw="flex ml-8">
                                <div tw="grid gap-8 xs:grid-cols-2 pb-32">
                                    {/* <div tw="xs:grid xs:p-2 xs:gap-10 xs:grid-rows-4 xs:grid-cols-2  xs:grid-flow-row"> */}
                                    <div tw="grid gap-10 md:grid md:p-2 md:gap-10 md:grid-rows-4 md:grid-cols-2 md:grid-flow-row">
                                        {getSVGS(svgList)}
                                    </div>
                                    <div tw="justify-end ml-10 xs:grid xs:grid-cols-3" className="kg-cap__description">
                                        <div tw="xs:col-span-2 "className="copy">
                                            <h3 className="kg-standard__subheading">Founder Has Extensive Experience</h3>
                                            <p className="kg-standard__copy">
                                            The founder of White Owl Education, Ralph Brooks, has provided data science and software engineering services for many companies in the Fortune 500.   
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}


export const WorkAndClients = UnstyledWorkAndClients


